exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    const currentPosition = getSavedScrollPosition(location);
    if (!location.hash){
        setTimeout(() => {
            window.scrollTo({ top: (currentPosition[0] || 0), left: (currentPosition[1] || 0), behavior: 'smooth' });
        }, 0);
        return false;
    }
};
// This seems to be a bug in Gatsby when css html { scroll-behavior: smooth; } is used in conjunction with a sticky element. Gatsby contributor in Github says they should have this patched soon.
// You can see this gatsby github page for more referenece - https://github.com/gatsbyjs/gatsby/issues/38201#issuecomment-1631378720
// The key is putting the window.scrollTo call in a setTimeout.
// The delay doesn't matter - even 0ms works.
// The point is to move it to later in the event loop, so that it won't be interrupted.